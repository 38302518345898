<template>
  <v-container fill-height fluid>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
// import { mapActions } from "vuex";

export default {
  name: "AuthorizedUserAccess",
  data() {
    return {
      form: {},
      toast: new ResponseHelper(),
      overlay: false,
    };
  },
  async created() {
    await this.authenticateUserAccess();
  },
  methods: {
    async authenticateUserAccess() {
      window.localStorage.removeItem("user");
      this.overlay = true;
      try {
        const userId = await this.getUserId();
        const response = await window.axios.get(
          "authenticate-user-access/" + userId
        );

        // 1. Clear previous user data first
        this.$store.commit("auth/LOGOUT");

        // 2. Store new user data in localStorage
        window.localStorage.setItem("user", JSON.stringify(response.data));

        // 3. Update Vuex state with new user data
        this.$store.commit("auth/USER_DATA", response.data);
        this.$store.commit("auth/AUTH_TOKEN", response.data);

        this.toast.sendSuccess(response);

        setTimeout(async () => {
          await this.$router.replace({ name: "Dashboard" });
        }, 2000);
      } catch (e) {
        this.$response.sendError(e);
      } finally {
        this.overlay = false;
      }
    },
    async getUserId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped></style>
